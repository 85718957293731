@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

.wrapper {
  /* margin: 0 auto; */
  /* width: 1140px; */
}
body,html,p,h1,h2,div,span {
  font-family: 'Source Sans Pro', sans-serif;
}
a {
  text-decoration: none;
  color: #070157;
  transition: 0.5s ease;
}
.clear {
  clear: both;
}
.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 10px;
}
.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
}
strong {
  font-weight: 600;
}
body {
  font-family: "Roboto", sans-serif;
  height: auto;
  width: 100%;
}
.bg {
  /* background: url('../images/bg.jpg')no-repeat; */
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  background: #6D6F70;
}

.car {
  /* position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 36%; */
}
.car img {
  /* width: 100%; */
}

.car-box {
  margin-bottom: 5%;
}
.car-box.car_vitara, .car-box.car_jimny, .car-box.car_swift {
  margin-bottom: 5%;
}
.car-box.car_ertiga, .car-box.car_dzire {
  margin-bottom: 5%;
}

@media only screen and (max-width: 600px) {
  .car-box {
    margin-bottom: 0%;
  }
  .car-box.car_vitara, .car-box.car_jimny, .car-box.car_swift {
    margin-bottom: 0%;
  }
  .car-box.car_ertiga, .car-box.car_dzire {
    margin-bottom: 0%;
  }

}

.bottom-nav {
  /* position: absolute; */
  /* bottom: 5%; */
  left: 0;
  right: 0;
  width: 600px;
  margin: 0 auto;
}
.bottom-nav-button {
  padding: 10px 30px;
  background: rgba(26, 24, 24, 0.8);
  border-radius: 7px;
  color: #fff;
  font-size: 16px;
  border: none;
  cursor: pointer;
  height: 50px;
}
.bottom-nav-button:hover {
  background: rgba(227, 0, 29, 1);
  color: #fff;
}
.bottom-nav .left {
  float: left;
}
.bottom-nav .right {
  float: right;
}
nav {
  /* background-color: red; */
  /* width: 60vmin; */
  /* margin: auto; */
  /* position: absolute;
    left: 21%;
    top: -6px; */
}
nav ul li {
  position: relative;
  /* float: left; */
  /* margin-right: 10px; */
}
nav ul li a {
  background: #383738 url("../images/icon1.png") no-repeat center;
  font-size: 0;
  display: block;
  text-align: center;
}
nav ul li a:hover {
  background: #fff url("../images/icon1-hover.png") no-repeat center center;
}
nav ul li:nth-child(2n) a {
  background: #383738 url("../images/icon2.png") no-repeat center center;
}
nav ul li:nth-child(3n) a:hover {
  background: #fff url("../images/icon2-hover.png") no-repeat center center;
}
nav ul li:nth-child(4n) a {
  background: #383738 url("../images/icon3.png") no-repeat center center;
}
nav ul li:nth-child(4n) a:hover {
  background: #fff url("../images/icon3-hover.png") no-repeat center center;
}
nav ul li:nth-child(5n) a {
  background: #383738 url("../images/icon4.png") no-repeat center center;
}
nav ul li:nth-child(5n) a:hover {
  background: #fff url("../images/icon4-hover.png") no-repeat center center;
}
nav ul li:nth-child(6n) a {
  background: #383738 url("../images/icon5.png") no-repeat center center;
}
nav ul li:nth-child(6n) a:hover {
  background: #fff url("../images/icon5-hover.png") no-repeat center center;
}
nav ul li:nth-child(7n) a {
  background: #383738 url("../images/icon6.png") no-repeat center center;
}
nav ul li:nth-child(7n) a:hover {
  background: #fff url("../images/icon6-hover.png") no-repeat center center;
}
nav ul li:hover .sub-menu {
  display: block;
}
.sub-menu {
  display: none;
  position: absolute;
  bottom: 150%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.9);
  width: auto;
  text-align: left;
  border-radius: 10px;
  left: -10px;
  white-space: nowrap;
}

/* .sub-menu.body-type {
  left: 0px;
} */

.sub-menu.list {
  white-space: normal;
  top: inherit;
  bottom: 140%;
}

.sub-menu.list ul {
  width: 420px;
}

.sub-menu.list ul li::before {
  display: none;
}

.sub-menu.list ul li:first-child::after {
  position: absolute;
  width: 2px;
  padding: 0px;
  height: 100%;
  left: 90%;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.sub-menu.list ul::before {
  top: 100%;
}

.sub-menu.color {
  bottom: 100%;
  padding: 0px;
  background: transparent;
  text-align: left;
  left: -1px;
}
nav ul li ul::before {
  content: " ";
  background: url("../images/arrow.png") no-repeat;
  position: absolute;
  top: 100%;
  width: 27px;
  height: 25px;
  left: 15px;
}
.sub-menu ul li {
  display: block;
  float: none;
  margin-right: 0;
  margin-bottom: 5px;
}
.sub-menu ul li:after {
  /* content: "|"; */
  color: #717070;
  padding: 0 10px;
}
.sub-menu ul li:last-child:after {
  content: "";
  padding: 0;
}
.sub-menu ul li a {
  font-size: 18px;
  color: black;
  width: auto;
  height: auto;
  background: none !important;
  display: inline-block;
}
.sub-menu ul li a:hover {
  color: #e3001d;
  background: none !important;
}

.sub-menu ul li a.selected-text {
  color: #e3001d;
  font-weight: bolder;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border-radius: 0px!important;
  box-shadow: 0px!important;
  /* padding: 20px; */
  /* border: 1px solid #888; */
  border: none!important;
  width: 100%;
}

.modal-title {
  font-size: 17px!important;
}
.modal-header {
  padding: 0rem 1rem!important;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/*
.bbox {
    width: 40vmin;
    height: 20vmin;
} */

#interior-modal .modal-content,
#interior-modal .modal-body {
  padding: 0;
}


@media screen and (max-width: 780px) {
  .modal {
    padding-top: 0px;
  }
  .modal-dialog {
    margin: 0px!important;
  }
  .sub-menu {
    right: inherit;
    /* bottom: inherit; */
    bottom: 0%;
    left: -50px;
    border-radius: 0px;
    padding: 5px 15px;
    z-index: 11;
  }

  .sub-menu.body-type {
    left: 0px;
    bottom: 100%;
  }

  .sub-menu ul::before {
    display: none;
    left: 60px;
  }

  .sub-menu ul li {
    display: block;
    width: 100%;
    text-align: left;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    border-bottom: 1px solid lightgray;
  }

  .sub-menu ul li a {
    font-size: 15px;
  }

  .sub-menu ul li:last-child {
    border: none;
  }

  .sub-menu ul li::after {
    display: none;
  }

  .sub-menu.list {
    left: inherit;
    right: -50px;
    bottom: 100%;
    /* top: 100%; */
  }

  .sub-menu.list ul {
    width: 300px;
  }
  .sub-menu.list ul li:first-child::after {
    left: 88%;
  }
  .sub-menu.list ul::before {
    top: 100%;
    left: inherit;
    right: 70px;
  }
  nav ul li:nth-child(2n) a {
    background-size: 25px;
  }
  nav ul li:nth-child(2n) a:hover {
    background-size: 25px;
  }

  nav ul li:nth-child(3n) a {
    background-size: 25px;
  }
  nav ul li:nth-child(3n) a:hover {
    background-size: 25px;
  }
  nav ul li:nth-child(4n) a {
    background-size: 25px;
  }
  nav ul li:nth-child(4n) a:hover {
    background-size: 25px;
  }
  nav ul li:nth-child(5n) a {
    background-size: 25px;
  }
  nav ul li:nth-child(5n) a:hover {
    background-size: 25px;
  }
  nav ul li:nth-child(6n) a {
    background-size: 25px;
  }
  nav ul li:nth-child(6n) a:hover {
    background-size: 25px;
  }
  nav ul li:nth-child(7n) a {
    background-size: 25px;
  }
  nav ul li:nth-child(7n) a:hover {
    background-size: 25px;
  }
}

@media screen and (max-width: 400px) {
  .sub-menu.list ul {
    width: 200px;
  }
}

body::-webkit-scrollbar {
  width: 0px;
}

